.recipe-entry {
  margin-top: 1.25em;
  display: flex;
  align-items: center;
}

.recipe-entry:first-child {
  margin-top: 0;
}

.recipe-entry img {
  width: 100%;
}

.recipe-entry .thumb-container {
  display: flex;
  align-items: center;
  width: 10em;
  max-height: 7.5em;
  overflow: hidden;
  border-radius: 0.25em;
}

.recipe-entry .missing-thumb {
  width: 100%;
  height: 6.5em;
  background-color: lightgray;
  font-weight: bold;
  color: gray;
  text-align: center;
  padding-top: 2.5em;
}

.ingredients {
  margin: 0.2em 0;
}

.ingredient-tag {
  display: inline-block;
  box-sizing: content-box;
  cursor: pointer;
  border: 1px solid #bbb;
  padding: 0.35em;
  border-radius: 0.25em;
  margin-right: 0.3em;
  margin-top: 0.3em;
  background-color: white;
}

.ingredient-tag:hover {
  border-color: gray;
}

.navbar {
  background-color: rgb(44, 165, 44);
  font-weight: 600;
}

ul.suggestions {
  width: 100%;
  list-style-type: none;
  padding: 0;
  background-color: white;
}

ul.suggestions li {
  border-bottom: 1px solid lightgray;
  padding: 0.5em;
  cursor: pointer;
}

ul.suggestions li:last-child {
  border-bottom: none;
}

ul.suggestions li:hover {
  background-color: #f8f8f8;
}

table.stats {
  margin: 1em 2em 1em 1em;
}

table.stats td {
  padding-right: 1em;
}

table.stats.sources tbody tr:last-child {
  border-top: 1px solid gray;
}

.non-matching-ingredient {
  cursor: pointer;
}

.non-matching-ingredient::after {
  content: ", ";
}

.non-matching-ingredient:last-child::after {
  content: none;
}
